<template>
  <CoreMenusTableRowFrame @click="emitClick">
    <template v-slot:colOne>
      <div v-if="coreMenu.organisation.users.length" class="flex items-center">
        <UserAvatar variant="primary" :user="franchisee" />
        <div class="min-w-0 pl-3 text-sm">
          <div class="truncate font-medium text-neutral-900">
            {{ franchisee.firstName }}
            {{ franchisee.lastName }}
          </div>
          <div class="truncate text-neutral-400">
            <div class="hidden md:block">
              {{ franchisee.email }}
            </div>
            <div class="md:hidden">{{ coreMenu.organisation.name }}</div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:colTwo>
      <div class="text-sm text-neutral-600">
        {{ coreMenu.organisation.name }}
      </div>
    </template>
    <template v-slot:colThree>
      <div class="text-sm">
        <div class="truncate flex items-center text-neutral-600">
          <country-flag
            :country="coreMenu.organisation.country.code"
            size="small"
          />
          <span class="ml-2">{{ countryName }}</span>
        </div>
        <div class="text-neutral-400">
          {{
            $tc(`global.locationsAmount`, coreMenu.organisation.stores.length, {
              amount: coreMenu.organisation.stores.length,
            })
          }}
        </div>
      </div>
    </template>
    <template v-slot:colFour>
      <div class="flex items-center justify-between">
        <div class="text-sm text-neutral-400">
          {{ formatDate(coreMenu.createdAt) }}
        </div>
        <div>
          <BaseButton size="medium" variant="outline" @buttonClick="() => {}">
            <template #iconCenter>
              <IconEdit class="text-neutral-400" />
            </template>
          </BaseButton>
        </div>
      </div>
    </template>
  </CoreMenusTableRowFrame>
</template>

<script>
import CoreMenusTableRowFrame from "@/components/menus/core-menus/CoreMenusTableRowFrame";
import UserAvatar from "@/components/common/UserAvatar";
import countries from "i18n-iso-countries";
import findFranchisee from "@/utils/findFranchisee";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

export default {
  components: {
    CoreMenusTableRowFrame,
    UserAvatar,
  },
  props: {
    coreMenu: {
      type: Object,
      required: true,
    },
  },
  computed: {
    countryName() {
      return countries.getName(
        this.coreMenu.organisation.country.code,
        this.$i18n.locale,
      );
    },
    franchisee() {
      return findFranchisee(
        this.coreMenu.organisation.users,
        this.coreMenu.organisation.users[0],
      );
    },
  },
  methods: {
    formatDate(date) {
      const parsedDate = new Date(date);
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
      };
      return new Intl.DateTimeFormat("en-US", options).format(parsedDate);
    },
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
