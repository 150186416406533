<template>
  <div class="sm:container">
    <TableFrame>
      <TableHeader>
        <template v-slot:title>
          {{ $t("components.menus.coreMenusTitle") }}
        </template>
        <template v-slot:description>
          {{ $t("components.menus.coreMenusDescription") }}
        </template>
        <template v-slot:actions>
          <BaseButton
            v-if="$can('write:menu')"
            size="medium"
            variant="fill"
            :text="$t(`components.menus.createNewCoreMenu`)"
            @buttonClick="redirectToMasterDishList"
          >
            <template #iconLeft>
              <IconPlus />
            </template>
          </BaseButton>
        </template>
      </TableHeader>
      <div v-if="coreMenus.length > 0">
        <CoreMenusTableLegend />
        <CoreMenusTableRow
          v-for="coreMenu in coreMenus"
          :key="coreMenu.index"
          :core-menu="coreMenu"
          @click="editCoreMenu(coreMenu)"
        />
      </div>
      <div
        v-else
        class="border-t border-neutral-50 bg-neutral-0 p-5 text-center text-sm text-neutral-400"
      >
        {{ $t("components.menus.noCoreMenus") }}
      </div>
    </TableFrame>
  </div>
</template>

<script>
import TableFrame from "@/components/common/table/TableFrame";
import TableHeader from "@/components/common/table/TableHeader";
import CoreMenusTableLegend from "@/components/menus/core-menus/CoreMenusTableLegend";
import CoreMenusTableRow from "@/components/menus/core-menus/CoreMenusTableRow";

import store from "@/store";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "CoreMenus",

  components: {
    TableFrame,
    TableHeader,
    CoreMenusTableLegend,
    CoreMenusTableRow,
  },

  beforeRouteEnter(_to, _from, next) {
    Promise.all([store.dispatch("coreMenu/fetchCoreMenus")]).finally(() => {
      next();
    });
  },

  computed: {
    ...mapGetters("coreMenu", ["coreMenus"]),
  },

  methods: {
    ...mapActions("coreMenu", ["editCoreMenu"]),
    redirectToMasterDishList() {
      this.$router.push({
        name: "master-dish-list",
      });
    },
  },
};
</script>
