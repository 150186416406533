<template>
  <CoreMenusTableLegendFrame>
    <template v-slot:colOne>
      {{ $t("global.franchisee") }}
    </template>
    <template v-slot:colTwo>
      {{ $t("global.organisation") }}
    </template>
    <template v-slot:colThree>
      {{ $t("global.operatingArea") }}
    </template>
    <template v-slot:colFour>
      {{ $t("global.createDate") }}
    </template>
    <template v-slot:colFive></template>
  </CoreMenusTableLegendFrame>
</template>

<script>
import CoreMenusTableLegendFrame from "@/components/menus/core-menus/CoreMenusTableLegendFrame";

export default {
  components: {
    CoreMenusTableLegendFrame,
  },
};
</script>
