<template>
  <div
    class="border-t flex cursor-pointer border-neutral-50 px-3 transition-all duration-200 hover:bg-neutral-0 md:px-6"
  >
    <div
      class="flex-grow w-3/12 flex-col justify-center p-2 py-4 pr-4"
      @click="emitClick"
    >
      <slot name="colOne"></slot>
    </div>
    <div
      class="hidden w-3/12 flex-col justify-center p-2 py-4 pr-4 xl:flex"
      @click="emitClick"
    >
      <slot name="colTwo"></slot>
    </div>
    <div
      class="hidden w-3/12 flex-col justify-center p-2 py-4 pr-4 xl:flex"
      @click="emitClick"
    >
      <slot name="colThree"></slot>
    </div>
    <div
      class="hidden w-5/12 flex-col justify-center p-2 py-4 pr-4 sm:flex xl:w-3/12"
      @click="emitClick"
    >
      <slot name="colFour"></slot>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>
