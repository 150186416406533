export default function (users, fallback) {
  const franchisee = users.find(
    (user) =>
      user.role.name == "franchisee" || user.role.name == "masterFranchise",
  );
  if (franchisee) {
    return franchisee;
  }
  return fallback;
}
